import React, { useContext } from "react";
import { toTextBlock } from "../lib/helpers";
import {
  getComponentMap,
  PageHeader,
  PageSectionSimple,
  SectionWrapper,
  Subscribe,
} from "./sections";
import { CardGrid } from "./card/card-grid";
import { CategoryList } from "./sections/categories";
import { SiteContext } from "./global/site-context";
import { Button } from "./button";
import { Filters } from "./sections/filters";
import { COMMUNITY_TAGS } from "./card/pills";

export const CategoryPage = ({ _id, title, titlePublic, docs, sections }) => {
  const site = useContext(SiteContext);
  const communityCategory = COMMUNITY_TAGS.includes(_id);
  const available = getComponentMap();

  const subscribeSection = {
    background: "",
    header: site.token("subscribeHeader"),
    layout: "centered",
    anchor: "subscribe",
    name: "subscribe",
    attribution: {
      campaignType: "series-footer",
      website_attribution: "series-footer",
    },
    noHeaders: true,
  };

  return (
    <>
      {sections.map((section, i) => {
        //section.container = "narrow";
        switch (section._type) {
          case "pageSectionSimple":
            return (
              <SectionWrapper {...section} key={i}>
                <PageSectionSimple section={section} />
              </SectionWrapper>
            );

          case "pageComponent":
            if (available.has(section.name)) {
              const sectionObj = available.get(section.name);
              const Tag = sectionObj.component;

              return (
                <SectionWrapper
                  {...{
                    ...section,
                    noHeaders: sectionObj.noHeaders,
                    desktopOnly: sectionObj.desktopOnly,
                  }}
                  key={i}
                  index={i}
                >
                  <Tag section={section} />
                </SectionWrapper>
              );
            }
            return (
              <SectionWrapper {...section} key={i}>
                <p>Missing Component: {section.name}</p>
              </SectionWrapper>
            );

          default:
            return JSON.stringify(section);
        }
      })}

      <SectionWrapper background="" padding="none" container="full">
        <Filters />
      </SectionWrapper>

      <SectionWrapper background="" container="narrow">
        {docs && (
          <CardGrid
            maxColumns={1}
            nodes={docs}
            hideType={true}
            showSubTags={true}
            browseMoreHref="/blog"
          />
        )}
      </SectionWrapper>

      <SectionWrapper {...subscribeSection}>
        <Subscribe section={subscribeSection} showBox={true} />
      </SectionWrapper>
    </>
  );
};
