import React, { useContext, useState } from "react";
import { toTextBlock } from "../lib/helpers";
import {
  ChannelsButtons,
  PageHeader,
  PageSectionSimple,
  SectionWrapper,
  Subscribe,
  getComponentMap,
} from "./sections";
import { CardGrid } from "./card/card-grid";
import {
  addDays,
  addMinutes,
  format,
  isFuture,
  parseISO,
  subDays,
} from "date-fns";
import { Byline } from "./card/byline";
import { pageDocPath } from "../../helpers";
import { Button } from "./button";
import { CardContext } from "./card/context";
import { SiteContext } from "./global/site-context";

export const SeriesPage = ({
  title,
  titlePublic,
  sections,
  docs,
  _rawExcerpt,
  events,
  graphic,
}) => {
  const available = getComponentMap();
  const event = events.length ? events[0] : null;

  const [eventDate] = useState(
    event ? subDays(parseISO(event.startDate), 0) : null
  );
  const [showEvent] = useState(
    eventDate ? isFuture(addDays(eventDate, 1)) : null
  );

  return (
    <>
      <SectionWrapper
        {...{
          container: "large",
          noHeaders: true,
          background: "dots",
          name: "header",
          layout: "centered",
          container: "thin",
          padding: "top",
        }}
      >
        <PageHeader
          section={{
            _rawSummary: toTextBlock(titlePublic || title, "h1").concat(
              _rawExcerpt
            ),
            layout: "centered",
          }}
        />
      </SectionWrapper>

      <SectionWrapper
        {...{
          container: showEvent ? "thin" : "large",
          noHeaders: true,
          background: "dots",
          name: "header", // allows dots
          padding: "bottom",
          layout: showEvent ? "centered" : null,
        }}
      >
        {showEvent ? (
          <>
            <h3 className="font-bold text-lg py-8 text-center">
              Register for our upcoming event
            </h3>
            <CardContext.Provider value={event}>
              <NextEventCard doc={event} eventDate={eventDate} />
            </CardContext.Provider>
          </>
        ) : (
          <div className="pt-12">
            <Subscribe
              section={{
                header: "Subscribe and never miss an episode",
                formDomId: "mavericks-signup",
                placeholder: "Email",
              }}
              showBox={true}
            />
            <div className="mt-12 text-center">
              <p className="font-semibold mb-3">Or find us here:</p>
              <ChannelsButtons />
            </div>
          </div>
        )}
      </SectionWrapper>

      {showEvent && (
        <SectionWrapper layout="centered" padding="top">
          <Subscribe
            section={{
              header: "Subscribe and never miss an episode",
              formDomId: "mavericks-signup",
              placeholder: "Email",
            }}
            showBox={true}
          />
        </SectionWrapper>
      )}

      <SectionWrapper
        container="large"
        layout="centered"
        header="Explore episodes"
      >
        {/* <Filters nodes={docs.slice(1, docs.length)} setNodes={setNodes} /> */}
        {docs && (
          <CardGrid
            maxColumns={2}
            nodes={docs}
            hideEvents={true}
            hideTitles={true}
            showSummary={true}
            showSubTags={true}
            // hideDate={true}
            panels={true}
            showExtended={true}
            hideType={true}
          />
        )}
      </SectionWrapper>

      {/* @todo share with page and event document pages */}
      {sections.map((section, i) => {
        //section.container = "narrow";
        switch (section._type) {
          case "pageSectionSimple":
            return (
              <SectionWrapper {...section} key={i}>
                <PageSectionSimple section={section} />
              </SectionWrapper>
            );

          case "pageComponent":
            if (available.has(section.name)) {
              const sectionObj = available.get(section.name);
              const Tag = sectionObj.component;

              return (
                <SectionWrapper
                  {...{
                    ...section,
                    noHeaders: sectionObj.noHeaders,
                    desktopOnly: sectionObj.desktopOnly,
                  }}
                  key={i}
                  index={i}
                >
                  <Tag section={section} />
                </SectionWrapper>
              );
            }
            return (
              <SectionWrapper {...section} key={i}>
                <p>Missing Component: {section.name}</p>
              </SectionWrapper>
            );

          default:
            return JSON.stringify(section);
        }
      })}
    </>
  );
};

const NextEventCard = ({ doc, eventDate }) => {
  const site = useContext(SiteContext);

  return (
    <a
      className="w-full group transition-link rounded-lg overflow-hidden border border-dark-5 border-black box-border relative block p-6"
      style={{
        background:
          "radial-gradient(56.12% 73.00% at 37.75% -3.18%, rgba(107, 255, 228, 0.25) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(58.30% 72.52% at 69.25% 58.49%, rgba(207, 148, 59, 0.45) 0%, rgba(255, 143, 107, 0.00) 100%), linear-gradient(58deg, rgba(211, 183, 255, 0.15) 0%, rgba(235, 233, 117, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), #D3B7FF",
      }}
      href={doc.externalUrl || pageDocPath(doc)}
      target={doc.externalUrl ? "_blank" : null}
      onClick={() => {
        site.metrics.logClick("series-event");
      }}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 opacity-0 group-hover:opacity-20 bg-black mix-blend-overlay"></div>
      <div className="float-right">
        <Button
          className="mt-8 sm:mt-0"
          text="Register"
          bgColor="bg-black"
          noLink={true}
        />
      </div>
      <h3 className="text-xl md:text-2xl font-semibold leading-semibold tracking-tight mb-4">
        {doc.title}
      </h3>
      <div className="leading-snug">
        <p>{`${format(eventDate, "MMMM do, yyyy")}`}</p>
        <p>
          {`${format(eventDate, "h:mmaa")} - ${format(
            addMinutes(eventDate, doc.duration),
            "h:mmaa zzz"
          )}`}
        </p>
      </div>
      {doc.authors.length > 0 && (
        <div className="mt-4">
          <Byline maxAuthors={1} />
        </div>
      )}
    </a>
  );
};
