import { graphql } from "gatsby";
import React, { useContext } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { mapEdgesToNodes, toPlainText } from "../lib/helpers";
import { CategoryPage } from "../components/category-page";
import { SeriesPage } from "../components/series-page";
import { videoMetaData } from "./video-bulk";
import { SiteContext } from "../components/global/site-context";

export const query = graphql`
  query CategoryTemplateQuery($id: String!, $_id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      _type
      _id
      status
      title
      titlePublic
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
      isSeries
      #hideNav
      customPath
      graphic {
        ...SanityImageSimple
      }
      sections {
        ... on SanityPageComponent {
          _type
          name
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
          mainImage {
            ...SanityImageSimple
          }
          category
          effect
        }
        ... on SanityPageSectionSimple {
          _type
          _rawContent(resolveReferences: { maxDepth: 5 })
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
        }
      }
    }
    seriesVideos: allSanityVideo(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        series: { elemMatch: { id: { eq: $id } } }
        status: { in: ["published", "hidden"] }
      }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    seriesVideosBulk: allGoogleSpreadsheetWwWebsiteContentVideos(
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" }, series: { eq: $_id } }
    ) {
      edges {
        node {
          videoId
          slug
          status
          series
          title
          playlist
          community
          devRel
          gtm
          publishDate
        }
      }
    }
    seriesPosts: allSanityPost(
      limit: 72
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { id: { in: [$id] } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    seriesStories: allSanityStory(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { id: { in: [$id] } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
    allStories: allSanityStory(
      sort: { fields: [_createdAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

const CategoryPostTemplate = (props) => {
  const site = useContext(SiteContext);
  const { data = {} } = props;
  const { title, _rawExcerpt, mainImage, titlePublic } = data.category || {};

  const theDocs = mapEdgesToNodes(data.seriesVideos)
    .concat(mapEdgesToNodes(data.seriesVideosBulk).map(videoMetaData))
    .concat(mapEdgesToNodes(data.seriesPosts))
    .concat(
      site.isActive("storiesInBlog") && data.seriesStories
        ? mapEdgesToNodes(data.seriesStories)
        : []
    )
    .concat(
      site.isActive("storiesInBlog") &&
        props.pageContext?._id === "6d1042dd-59ad-481b-9257-a209f67eec42"
        ? mapEdgesToNodes(data.allStories)
        : []
    )
    .sort((a, b) =>
      (a.publishedAt || a._createdAt) < (b.publishedAt || b._createdAt) ? 1 : -1
    );

  return (
    <LayoutContainer doc={{ ...data.category }}>
      <PageHeadMeta
        title={titlePublic || title}
        description={toPlainText(_rawExcerpt)}
        image={mainImage}
        doc={data.category}
      />
      {/* data.category.isSeries */}
      {data.category.isSeries ? (
        <SeriesPage
          {...data.category}
          events={data.seriesEvents ? mapEdgesToNodes(data.seriesEvents) : []}
          docs={theDocs}
        />
      ) : (
        <CategoryPage {...data.category} docs={theDocs} />
      )}
    </LayoutContainer>
  );
};

export default CategoryPostTemplate;
